import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  transition: opacity 0.5s ease-in-out;
  padding: 20px 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-weight: bold;
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 24px;
`;

const Preference = styled.div`
  font-size: 14px;
  font-weight: normal;

  ${({ theme, lighten }) =>
    lighten &&
    `
    color: ${theme.colors.lightGrey};
  `}
`;

const EditLink = styled.a`
  font-size: 10px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  margin-top: 48px;
`;

const Section = ({ editing, title, preference, toggleEditing }) => (
  <Wrapper>
    <div>
      <Title>{title}</Title>
      <Preference lighten={editing}>{preference}</Preference>
    </div>
    <EditLink onClick={toggleEditing}>{editing ? "Close" : "Edit"}</EditLink>
  </Wrapper>
);

Section.defaultProps = {
  editing: false,
  preference: null,
};

Section.propTypes = {
  editing: PropTypes.bool,
  title: PropTypes.string.isRequired,
  preference: PropTypes.string,
  toggleEditing: PropTypes.func.isRequired,
};

export default Section;
